.css-49pv4o-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0;
}

.termMatchedSection {
  padding-top: 0 !important;
}

.css-1dybbl5-MuiChip-label {
  padding-left: 8px !important;
  padding-right: 2px !important;
}
